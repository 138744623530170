import React from "react"

import { Caption, Container, Count } from "./mediaFooter.styles"
import { MediaFooterProps } from "./mediaFooter.types"

export const MediaFooter = ({caption,count,total}:MediaFooterProps) => {
  if(caption || (count && total)){
    return (
      <Container>
        <Caption>{caption}</Caption>
        {count && total && <Count>{`${count} / ${total}`}</Count>}
      </Container>
    )
  }
  
  return null;
}