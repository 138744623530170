import styled from "styled-components"
import { getUnitAsPixels } from "../../../theme/layout"
import { getTypography, Type } from "../../../theme/typography"

export const Container = styled.div`
  display: flex;
  padding-top: ${getUnitAsPixels()};
`

export const Caption = styled.div`
  ${getTypography(Type.LABEL_2)}
  color: ${({theme}) => theme.primary.hex};
  flex: 1;
`

export const Count = styled.div`
  ${getTypography(Type.LABEL_2)}
  color: ${({theme}) => theme.primary.hex};
  flex: 0;
  white-space: nowrap;
  padding-inline-start: ${getUnitAsPixels({multiplier:2})};
`