import styled from 'styled-components';
import { ThemeType } from '../../../theme/color';
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { Link } from '../link';

export const Container = styled.ul`
  
`;

export const Item = styled.li`
  margin-bottom: ${getUnitAsPixels()};
  display: block;

  &:last-child{
    margin-bottom: 0;
  }
`;

export const ItemLink = styled(Link)`
  ${getTypography(Type.BODY_1)}
  text-decoration: none;
  color: ${({theme}) => theme.primary.hex};
  fill: ${({theme}) => theme.primary.hex};
`;