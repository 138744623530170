import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { isEmpty, map, path, prop } from 'ramda';

import { getSchema } from '../common/schema';

import { SEO } from '../components/core/seo';
import { BodyEditorial } from '../components/layout/body/editorial';
import { getInternalURL } from '../common/link';
import { CoverArticle } from '../components/cover/article';
import { useDictionary } from '../context/dictionary';

export const query = graphql`
  query BlogArticleQuery($id: String!, $topic: String) {
    settings: site {
      siteMetadata {
        siteUrl
      }
    }
    site: datoCmsSite {
      ...SiteInformation
    }
    page: datoCmsBlogArticle(
      id: { eq: $id }
    ) {
      id
      model {
        ...PageModel
      }
      meta {
        ...PageMeta
      }
      seo {
        ...PageSEO
      }
      slug
      locale
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      topics {
        ...Topic
      }
      tags {
        ...Tag
      }
      authors {
        ...Author
      }
      thumbnail{
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { crop: "focalpoint", fit: "crop", ar: "1:1" }
          aspectRatio: 1
        )
        width
        height
      }
      coverMedia{
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          forceBlurhash: false
          imgixParams: { crop: "focalpoint", fit: "crop", ar: "1:1" }
          aspectRatio: 1
        )
        width
        height
      }
      title
      introduction
      body {
        value
        blocks {
          ...InlineMediaInternal
          ...InlineMediaExternal
          ...InlineGallery
          ...InlineInstagram
          ...InlineQuote
          ...InlineIframe
          ...InlineTweet
          ...InlineSoundcloud
        }
      }
      links {
        ...LinkExternal
        ...LinkInternal
      }
      relatedArticles {
        ...Article
      }
    }
    topicArticles: allDatoCmsBlogArticle(
      filter: {slug: {ne: null}, thumbnail: {url: {ne: null}}, id: {ne: $id}, topics: {elemMatch: {id: {eq: $topic}}}},
      limit: 3
    ) {
      nodes {
        ...Article
      }
    }
    collection: datoCmsBlogArticleCollection(items: {elemMatch: {id: {eq: $id}}}){
      title
      items {
        ...Article
      }
    }
  }
`;

const BlogArticle = ({ data, pageContext }: any) => {
  const settings:any = path(['settings', 'siteMetadata'], data);
  const topicArticles:any = path(['topicArticles', 'nodes'], data, []);
  const page = prop('page', data);
  const site = prop('site', data);
  const collection = prop('collection',data);

  if (!page || !settings || !site) return null;

  const schema = getSchema({ settings, site, page, pageContext });

  const labelBlogAuthored = useDictionary("labelBlogAuthored");
  const textBlogArticleLegal = useDictionary("textBlogArticleLegal");
  

  const relatedArticles = page.relatedArticles.concat(topicArticles).reduce((unique,item) => unique.find((uniqueItem) => uniqueItem.id === item.id) ? unique : [...unique,item], []).slice(0,3);

  return (
    <Fragment>
      <SEO
        tags={page.seoMetaTags}
        favicon={site.faviconMetaTags}
        siteURL={settings.siteUrl}
        schema={schema}
        locale={pageContext.locale}
        defaultLocale={pageContext.defaultLocale}
        alternateLocales={pageContext.alternateLocales}
        isArticle={true}
      />
      <CoverArticle heading={page.title} media={page.coverMedia} breadcrumb={page.topics[0]} date={page.meta.firstPublishedAtFormatted} caption={{label:labelBlogAuthored,value:page.authors}} legal={textBlogArticleLegal} siteURL={settings.siteUrl} slug={page.slug} />
      <BodyEditorial id={page.id} content={page.body} introduction={page.introduction} links={page.links} tags={page.tags} relatedArticles={relatedArticles} collection={collection} />
    </Fragment>
  );
};

export default BlogArticle;