import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { CarouselProps } from "./carousel.types";
import { ResponsiveMedia } from "../responsiveMedia";
import { Container, Item, Left, LeftBtn, Media, Right, RightBtn } from "./carousel.styles";
import { useIsRTL } from "../../../context/locale";
import SmallArrowIcon from '../../../images/small-arrow.svg';


/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export const Carousel = ({items,onSelect}:CarouselProps) => {
  const rtl = useIsRTL();
  
  const [[page, direction], setPage] = useState([0, 0]);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, items.length, page);

  const [index, setIndex] = useState(0);

  React.useEffect(() => {
    const newIndex = wrap(0, items.length, page)
    setIndex(newIndex);

    if(onSelect){
      onSelect({index:newIndex,item:items[newIndex]});
    }
  },[page])

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  const variants = {
    enter: (direction: number) => {
      return {
        x: (rtl && direction < 0) || (!rtl && direction > 0) ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => {
      return {
        zIndex: 0,
        x: (rtl && direction > 0) || (!rtl && direction < 0) ? 1000 : -1000,
        opacity: 0
      };
    }
  };

  const handleLeftClick = () => {
    paginate(rtl ? 1 : -1);
  }

  const handleRightClick = () => {
    paginate(rtl ? -1 : 1);
  }

  return (
    <Container>
      <AnimatePresence initial={false} custom={direction}>
        <Item
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "ease", stiffness: 300, damping: 10 },
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(rtl ? -1 : 1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(rtl ? 1 : -1);
            }
          }}
        >
          <Media defaultSource={items[index]} />
        </Item>
      </AnimatePresence>
      <LeftBtn onClick={handleLeftClick}>
        <SmallArrowIcon />
      </LeftBtn>
      <RightBtn onClick={handleRightClick}>
        <SmallArrowIcon />
      </RightBtn>
    </Container>
  );
};
