import styled from "styled-components";
import { motion } from "framer-motion";
import { ResponsiveMedia } from "../responsiveMedia";
import { getMediaQuery, getSpacerAsPixels, Size } from "../../../theme/layout";

export const Container = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 100%;
`

export const Item = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Media = styled(ResponsiveMedia)`
  flex: 1;
`

export const Btn = styled.button`
  position: absolute;
  width: ${getSpacerAsPixels({multiplier:1.5})};
  height: ${getSpacerAsPixels({multiplier:1.5})};
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.common.primary.hex};
  top: 50%;
  z-index: 1;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  >svg{
    fill: ${({theme}) => theme.colors.common.dark.hex};
  }

  ${getMediaQuery(Size.MEDIUM)}{
    display: flex;
  }
`

export const LeftBtn = styled(Btn)`
  transform: translate(0,-50%);
  left: ${getSpacerAsPixels({multiplier:1})};
  
  >svg{
    transform: scale(-1,1);
  }
  
`

export const RightBtn = styled(Btn)`
  transform: translate(0,-50%);
  right: ${getSpacerAsPixels({multiplier:1})};
`