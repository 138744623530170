import React, { useState } from "react"
import { Container, Content } from "./gallery.styles"
import { Slider } from "../../core/slider";
import { MediaInternal } from "../../../common/types";
import { Carousel } from "../../core/carousel";
import { MediaFooter } from "../../core/mediaFooter";

export interface InlineGalleryProps {
  items:Array<MediaInternal>
}

export const InlineGallery = ({ items }:InlineGalleryProps) => {

  const [selectedIndex,setSelectedIndex] = useState(0);
  const [selectedItem,setSelectedItem] = useState(items[0]);

  const handleSelect = ({index,item}) => {
    setSelectedIndex(index);
    setSelectedItem(item);
  }

  return (
      <Container>
        <Content>
          <Carousel items={items} onSelect={handleSelect} />
        </Content>
        <MediaFooter caption={selectedItem.title} count={selectedIndex + 1} total={items.length} />
      </Container>
  )
}