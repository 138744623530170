import React, { useRef } from 'react';
import { Size } from '../../../theme/layout';
import { MediaFooter } from '../../core/mediaFooter';
import { Container, Media } from './mediaInternal.styles';

export const InlineMediaInternal = ({media,featured}:{media:any,featured?:boolean}) => {

  const sizes = {
    [Size.SMALL]: {span: 8},
    [Size.MEDIUM]: {span: 12},
    [Size.LARGE]: {span: 8},
    [Size.XLARGE]: {span: 7},
    [Size.MAX]: {span: 7},
  };

  return (
    <Container featured={featured}>
      <Media defaultSource={media} />
      <MediaFooter caption={media.title} />
    </Container>
  )
};