import React from 'react';
import { Container, Content } from './iframe.styles';

export const InlineIframe = ({url,aspectRatio}) => {

  return (
    <Container aspectRatio={aspectRatio}>
      <Content src={url} />
    </Container>
  )
};