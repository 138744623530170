import Color from "color"
import styled from "styled-components"
import { boolean } from "zod"
import { getUnitAsPixels } from "../../../theme/layout"
import { getTypography, Type } from "../../../theme/typography"
import { LinkInternal } from "../../core/link"

export const Container = styled.div<{spaceAtTop:boolean}>`
  margin-top: ${({spaceAtTop}) => spaceAtTop ? getUnitAsPixels({multiplier:4}) : 0};
`

export const Label = styled.span`
  ${getTypography(Type.LABEL_2)}
  color: ${({theme}) => theme.primary.hex};

  &:after{
    content: " ";
  }
`

export const Value = styled.span`
  ${getTypography(Type.LABEL_2)}
  color: ${({theme}) => theme.primary.hex};
`

export const Link = styled(LinkInternal)`
  text-decoration: none;
  ${getTypography(Type.LABEL_1)}
  color: ${({theme}) => theme.primary.hex};
  transition: color 0.3s;

  &:hover{
    color: ${({theme}) => Color(theme.primary.hex).isDark() ? Color(theme.primary.hex).mix(Color("white"), 0.3).toString() : Color(theme.primary.hex).mix(Color("black"), 0.3).toString()};
  }
`

export const Text = styled.span`
  display: block;
  ${getTypography(Type.LABEL_2)}
  color: ${({theme}) => theme.primary.hex};
`