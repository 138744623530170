import styled from 'styled-components';
import { CONTAINER, getLayout, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, Layout, Size, VerticalSpaceVariant } from '../../../../theme/layout';
import { getTypography, Type } from '../../../../theme/typography';

export const Container = styled.div`
  ${CONTAINER}
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`;

export const RelatedContainer = styled.section`
  ${CONTAINER}
  background-color: ${({theme}) => theme.background.hex};
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`

export const CollectionContainer = styled.section`
  ${CONTAINER}
  background-color: #F6F6F6;
  padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.LARGE)};

  ${getMediaQuery(Size.MEDIUM)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.LARGE)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.LARGE)};
  }
`

export const Introduction = styled.div`
  ${getTypography(Type.BODY_1)}

  >p {
    margin-bottom: ${getUnitAsPixels({multiplier:3})};
    
    ${getMediaQuery(Size.MEDIUM)}{
      margin-bottom: ${getSpacerAsPixels({multiplier:1})};
    }
  }
`

export const Content = styled.div`
  ${getLayout(Layout.GRID)}
`

export const Body = styled.div`
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }
  
  ${getMediaQuery(Size.LARGE)}{
    grid-column: 3 / span 8;
  }

  ${getMediaQuery(Size.XLARGE)}{
    grid-column: 4 / span 6;
  }
  
  p, ol, ul {
    ${getTypography(Type.BODY_2)}
  }

  >p, >ol, >ul {
    margin-bottom: ${getUnitAsPixels({multiplier:3})};
    
    ${getMediaQuery(Size.LARGE)}{
      margin-bottom: ${getSpacerAsPixels({multiplier:1})};
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

  ol {
    padding-inline-start: ${getUnitAsPixels({multiplier:4})};
    list-style: auto;

    li {
      padding-inline-start: ${getUnitAsPixels()};
    }
  }

  ul {
    padding-inline-start: ${getUnitAsPixels({multiplier:4})};
    list-style: disc;

    li {
      padding-inline-start: ${getUnitAsPixels()};
    }
  }

  a {
    color: ${({theme}) => theme.secondary.hex};
  }

  >h1, >h2, >h3, >h4, >h4, >h5, >h6 {
    ${getTypography(Type.SECTION_HEADING)}
    margin-bottom: ${getUnitAsPixels({multiplier:3})};
    
    ${getMediaQuery(Size.LARGE)}{
      margin-bottom: ${getSpacerAsPixels({multiplier:1})};
    }
  }
`;

export const Footer = styled.div`
  border-top: 2px solid ${({theme}) => theme.primary.hex};
  grid-column: 1 / span 8;
  margin-top: ${getUnitAsPixels({multiplier:3})};
  padding-top: ${getUnitAsPixels({multiplier:3})};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 3 / span 8;
    margin-top: ${getSpacerAsPixels()};
    padding-top: ${getSpacerAsPixels()};
  }

  ${getMediaQuery(Size.XLARGE)}{
    grid-column: 4 / span 6;
  }
`

export const FooterSection = styled.div`
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  &:last-child{
    margin-bottom: 0;
  }

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getSpacerAsPixels()};
  }
`

export const FooterHeading = styled.h2`
  ${getTypography(Type.LABEL_1)}
  margin-bottom: ${getUnitAsPixels({multiplier:1})};

  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getUnitAsPixels({multiplier:2})};
  }
`