import styled from 'styled-components';
import { ThemeType } from '../../../theme/color';
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from '../../../theme/layout';

export const Container = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${getUnitAsPixels({multiplier:-1})};
  margin-right: ${getUnitAsPixels({multiplier:-1})};
  margin-top: ${getUnitAsPixels({multiplier:-1})};
  margin-bottom: ${getUnitAsPixels({multiplier:1})};

  &:last-child{
    margin-bottom: ${getUnitAsPixels({multiplier:-1})};
  }

  ${getMediaQuery(Size.MEDIUM)}{
    display: block;
    // margin-left: ${getUnitAsPixels({multiplier:-1.5})};
    // margin-right: ${getUnitAsPixels({multiplier:-1.5})};
    // margin-top: ${getUnitAsPixels({multiplier:-1.5})};
    // margin-bottom: ${getUnitAsPixels({multiplier:1.5})};

    // &:last-child{
    //   margin-bottom: ${getUnitAsPixels({multiplier:-1.5})};
    // }
  }

  
`;

export const Item = styled.li`
  padding: ${getUnitAsPixels({multiplier:1})};
  display: inline-block;
  
  // ${getMediaQuery(Size.MEDIUM)}{
  //   padding: ${getUnitAsPixels({multiplier:1.5})};
  // }
`;