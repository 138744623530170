import React from 'react';
import { Link } from '../link/link.component';
import { Container, Item, ItemLink } from './linkList.styles';
import { LinkListProps } from './linkList.types';

export const LinkList = ({links}:LinkListProps) => {
  return (
    <Container>
      {links?.map((link,index) => (
        <Item>
          <ItemLink link={link} />
        </Item>
      ))}
    </Container>
  )
}