import React from 'react';
import { ThemeProvider } from 'styled-components';
import { CoverArticleProps } from './article.types';
import { FooterContainer, HeaderContainer, Heading, Legal, MediaContainer, Share } from './article.styles';
import { ResponsiveMedia } from '../../core/responsiveMedia';
import { useBrandTheme } from '../../../context/brand';
import { CoverMeta } from '../meta';
import { CoverCaption } from '../caption';
import { CoverContainer } from '../container';
import { Layout, Size } from '../../../theme/layout';
import { getArticlePath } from '../../../common/link';
import { useIsLocalized, useIsSingleCountry, useLocale } from '../../../context/locale';

export const CoverArticle = ({heading, breadcrumb, date, caption, legal, media, siteURL, slug}:CoverArticleProps) => {
  
  const theme = useBrandTheme("articleCover");

  const sizes = {
    [Size.SMALL]: {span: 8},
    [Size.MEDIUM]: {span: 12},
    [Size.LARGE]: {span: 8},
    [Size.XLARGE]: {span: 8},
    [Size.MAX]: {span: 8},
  };

  const isLocalized = useIsLocalized();
  const isSingleCountry = useIsSingleCountry();
  const locale = useLocale();

  const url = siteURL + getArticlePath({
    isLocalized,
    isSingleCountry,
    locale,
    slug
  });

  return (
    <ThemeProvider theme={theme}>
      <CoverContainer layout={Layout.GRID}>
        <HeaderContainer>
          <CoverMeta breadcrumb={breadcrumb} date={date} />
          <Heading>{heading}</Heading>
        </HeaderContainer>
        {media && <MediaContainer>
          <ResponsiveMedia defaultSource={media} sizes={sizes} />
        </MediaContainer>}
        <FooterContainer mediaIsVisible={!!media}>
          {caption && <CoverCaption spaceAtTop={false} {...caption} />}
          <Share url={url} title={heading} />
          {legal && <Legal>{legal}</Legal>}
        </FooterContainer>
      </CoverContainer>
    </ThemeProvider>
  )
};