import React from 'react';
import { DatoCmsInlineTweet } from '../../../common/types/graphql';

import { Container } from './tweet.styles';

export const InlineTweet = ({url,...props}:DatoCmsInlineTweet) => {
  const parts = url.split('/');
  const id = parts[parts.length - 1].split('?')[0];

  if(id){
    return (
      <Container>
        <blockquote className="twitter-tweet">
          <a href={url}></a>
        </blockquote>
        <script async src="//platform.twitter.com/widgets.js" />
      </Container>
    )
  }
  
  return null;
};

