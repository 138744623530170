import React from 'react';
import { DatoCmsInlineSoundcloud } from '../../../common/types/graphql';
import { Container, Content } from './soundcloud.styles';

export const InlineSoundcloud = ({url,...props}:DatoCmsInlineSoundcloud) => {
  return (
    <Container>
      <Content src={`https://w.soundcloud.com/player/?url=${url}`} />
    </Container>
  )
};