import styled from "styled-components";
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from "../../../theme/layout";

export const Container = styled.div`
  position: relative;
  height: 166px;
  margin-bottom: ${getUnitAsPixels({multiplier:3})};
    
  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }

  &:last-child{
    margin-bottom: 0;
  }
`;

export const Content = styled.iframe.attrs(props => ({
  allow:"autoplay"
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;