import React, { Suspense } from 'react';
import { DatoCmsInlineInstagram } from '../../../common/types/graphql';
import { Container, Content } from './instagram.styles';

export const InlineInstagram = ({url,...props}:DatoCmsInlineInstagram) => {
  return (
    <Suspense>
      <Container>
        <Content className="instagram-media" data-instgrm-permalink={url} data-instgrm-version="13" />
      </Container>
    </Suspense>
  )
};