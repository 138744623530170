import React, { Fragment } from 'react';
import { Container, Label, Link, Value, Text } from './caption.styles';
import { CoverCaptionProps } from './caption.types';

export const CoverCaption = ({label, value, text, spaceAtTop=true}:CoverCaptionProps) => {
  if((label && value && value.length > 0) || text){
    return (
      <Container spaceAtTop={spaceAtTop}>
        {label && value && value.length > 0 &&
          <>
          <Label>{label}</Label>
            <Value>{value.map((item,index) => (<Fragment key={index}><Link page={item} label={item.name} />{index < value.length - 1 ? `, ` : ``}</Fragment>))}</Value>
          </>
        }
        {text && <Text>{text}</Text>}
      </Container>
    )
  }
  return null;
};