import styled from "styled-components";
import { getGutterAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from "../../../theme/layout";
import { getTypography, Type } from "../../../theme/typography";

export const Container = styled.div`
  display: flex;
  text-align: center;
  margin-bottom: ${getUnitAsPixels({multiplier:3})};

  &:last-child{
    margin-bottom: 0;
  }
  
  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
    margin-left: calc(((100% - ${getGutterAsPixels(Size.LARGE,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.LARGE)}) * -1);
    margin-right: calc(((100% - ${getGutterAsPixels(Size.LARGE,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.LARGE)}) * -1);
  }

  ${getMediaQuery(Size.XLARGE)}{
    margin-left: calc(((100% - ${getGutterAsPixels(Size.XLARGE,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.XLARGE)}) * -1);
    margin-right: calc(((100% - ${getGutterAsPixels(Size.XLARGE,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.XLARGE)}) * -1);
  }

  ${getMediaQuery(Size.MAX)}{
    margin-left: calc(((100% - ${getGutterAsPixels(Size.MAX,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.MAX)}) * -1);
    margin-right: calc(((100% - ${getGutterAsPixels(Size.MAX,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.MAX)}) * -1);
  }
`;

export const Content = styled.div`
  flex: 1;
`

export const Text = styled.div`
  ${getTypography(Type.LARGE_HEADING)}
`;

export const Caption = styled.div`
  ${getTypography(Type.LABEL_2)}
  margin-top: ${getUnitAsPixels({multiplier:1})};

  &:before{
    content: '– ';
  }
`;