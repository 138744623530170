import React from 'react';
import { Container, Content, Text, Caption } from './quote.styles';

export const InlineQuote = ({text,caption}) => {

  return (
    <Container>
      <Content>
        <Text>“{text}”</Text>
        <Caption>{caption}</Caption>
      </Content>
    </Container>
  )
};