import styled from 'styled-components';
import { getGutterAsPixels, getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from '../../../theme/layout';
import { VideoPlayer } from '../../core/videoPlayer';

export const Container = styled.div<{featured?:boolean}>`
  margin-bottom: ${getUnitAsPixels({multiplier:3})};
  
  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }

  &:last-child{
    margin-bottom: 0;
  }

  ${({featured}) => featured && `
    ${getMediaQuery(Size.LARGE)}{
      margin-left: calc(((100% - ${getGutterAsPixels(Size.LARGE,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.LARGE)}) * -1);
      margin-right: calc(((100% - ${getGutterAsPixels(Size.LARGE,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.LARGE)}) * -1);
    }

    ${getMediaQuery(Size.XLARGE)}{
      margin-left: calc(((100% - ${getGutterAsPixels(Size.XLARGE,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.XLARGE)}) * -1);
      margin-right: calc(((100% - ${getGutterAsPixels(Size.XLARGE,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.XLARGE)}) * -1);
    }

    ${getMediaQuery(Size.MAX)}{
      margin-left: calc(((100% - ${getGutterAsPixels(Size.MAX,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.MAX)}) * -1);
      margin-right: calc(((100% - ${getGutterAsPixels(Size.MAX,{multiplier:5})}) / 6 + ${getGutterAsPixels(Size.MAX)}) * -1);
    }
  `}
`;

export const Media = styled(VideoPlayer)`
  
`;