import React, { useState } from "react"

import { ArticleCard } from "../articleCard/articleCard.component";
import { ArticleSliderProps } from "./articleSlider.types";
import { CardLayout } from "../../core/card/card.types";
import { CardContainer, CardContainerProgress, CardContainerSliderContent, CardContainerSliderItem } from "../../core/cardContainer";

export const ArticleSlider = ({ items, link, heading, heading2, subheading, selectedIndex, collection, expandable, expanded }:ArticleSliderProps) => {
  const [index, setIndex] = useState();
  const [maxIndex, setMaxIndex] = useState();

  const handleActiveIndexChange = (event) => {
    setIndex(event.index);
    setMaxIndex(event.maxIndex);
  }
  
  return (
    <CardContainer heading={heading} heading2={heading2} subheading={subheading} link={link} expandable={expandable} expanded={expanded}>
      <CardContainerSliderContent onActiveIndexChange={handleActiveIndexChange}>
        {items.map((item, index) => <CardContainerSliderItem key={item.id}><ArticleCard index={index} collection={collection} selected={index === selectedIndex} article={item} mobileLayout={CardLayout.VERTICAL} desktopLayout={CardLayout.VERTICAL} /></CardContainerSliderItem>)}
      </CardContainerSliderContent>
      <CardContainerProgress index={index} maxIndex={maxIndex} />
    </CardContainer>
  )
}