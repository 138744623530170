import React, { useRef } from 'react';
import { MediaFooter } from '../../core/mediaFooter';
import { Container, Media } from './mediaExternal.styles';

export const InlineMediaExternal = ({media,featured}:{media:any,featured?:boolean}) => {
  
  return (
    <Container featured={featured}>
      <Media {...media} />
      <MediaFooter caption={media.title} />
    </Container>
  )
};