import styled from 'styled-components';
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, getVerticalSpaceAsPixels, Size, VerticalSpaceVariant } from '../../../theme/layout';
import { getTypography, Type } from '../../../theme/typography';
import { ShareButtons } from '../../core/shareButtons';

export const Heading = styled.h1`
  ${getTypography(Type.HEADLINE)}
  grid-column: 1 / span 8;
  text-align: center;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }
`;

export const HeaderContainer = styled.div`
  grid-column: 1 / span 8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.LARGE)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.XLARGE)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
  }

  ${getMediaQuery(Size.MAX)}{
    padding-bottom: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
  }
`

export const MediaContainer = styled.div`
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 3 / span 8;
  }

  ${getMediaQuery(Size.XLARGE)}{
    grid-column: 4 / span 6;
  }
`

export const FooterContainer = styled.div<{mediaIsVisible:boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1 / span 8;

  ${getMediaQuery(Size.MEDIUM)}{
    grid-column: 1 / span 12;
  }

  ${getMediaQuery(Size.LARGE)}{
    grid-column: 4 / span 6;
  }

  ${({mediaIsVisible}) => mediaIsVisible ? `
    padding-top: ${getVerticalSpaceAsPixels(Size.SMALL,VerticalSpaceVariant.REGULAR)};

    ${getMediaQuery(Size.MEDIUM)}{
      grid-column: 1 / span 12;
      padding-top: ${getVerticalSpaceAsPixels(Size.MEDIUM,VerticalSpaceVariant.REGULAR)};
    }

    ${getMediaQuery(Size.LARGE)}{
      padding-top: ${getVerticalSpaceAsPixels(Size.LARGE,VerticalSpaceVariant.REGULAR)};
    }

    ${getMediaQuery(Size.XLARGE)}{
      padding-top: ${getVerticalSpaceAsPixels(Size.XLARGE,VerticalSpaceVariant.REGULAR)};
    }

    ${getMediaQuery(Size.MAX)}{
      padding-top: ${getVerticalSpaceAsPixels(Size.MAX,VerticalSpaceVariant.REGULAR)};
    }
    `
  : ``}
`

export const Share = styled(ShareButtons)`
  margin-top: ${getUnitAsPixels({multiplier:3})};
`;

export const Legal = styled.div`
  ${getTypography(Type.LABEL_2)}
  color: ${({theme}) => theme.primary.hex};
  margin-top: ${getUnitAsPixels({multiplier:3})};
`