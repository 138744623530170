import get from 'lodash/get';
import React, { ReactChild, ReactNode } from 'react';
import { StructuredText, StructuredTextDocument, StructuredTextPropTypes } from 'react-datocms';
import { ThemeProvider } from 'styled-components';
import { plainTextToHTML } from '../../../../common/text';
import { useBrandTheme } from '../../../../context/brand';
import { useDictionary } from '../../../../context/dictionary';
import { Layout } from '../../../../theme/layout';
import { ArticleList } from '../../../article/articleList';
import { ArticleSlider } from '../../../article/articleSlider';
import { BlockContainer } from '../../../block/container';
import { ButtonSize, ButtonVariant } from '../../../core/button/button.types';
import { ButtonLinks } from '../../../core/buttonLinks';
import { LinkList } from '../../../core/linkList';
import { InlineGallery } from '../../../inline/gallery';
import { InlineIframe } from '../../../inline/iframe';
import { InlineInstagram } from '../../../inline/instagram';
import { InlineMediaExternal } from '../../../inline/mediaExternal';
import { InlineMediaInternal } from '../../../inline/mediaInternal';
import { InlineQuote } from '../../../inline/quote';
import { InlineSoundcloud } from '../../../inline/soundcloud';
import { InlineTweet } from '../../../inline/tweet';
import { CollectionContainer, Container, Content, Body, Footer, FooterHeading, FooterSection, Introduction, RelatedContainer } from './editorial.styles';
import { BodyArticleProps } from './editorial.types';

const Collection = ({selectedId,items,title}) => {
  const selectedIndex = items.findIndex((i) => i.id === selectedId)
  const labelBlogPart = useDictionary("labelBlogPart");

  return (
    <CollectionContainer>
      <ArticleSlider items={items} heading={title} heading2={`${labelBlogPart} ${selectedIndex + 1}/${items.length}`} collection={true} selectedIndex={selectedIndex} expandable={true} />
    </CollectionContainer>
  )
}

export const BodyEditorial = ({id,content,introduction,links,tags,relatedArticles,collection}:BodyArticleProps) => {

  const relatedTheme = useBrandTheme("articleRelated");
  const labelBlogTagged = useDictionary("labelBlogTagged");
  const labelBlogLinks = useDictionary("labelBlogLinks");
  
  const labelBlogRelatedArticles = useDictionary("labelBlogRelatedArticles");

  return (
    <>
      {collection && <Collection selectedId={id} {...collection} />}
      <Container>
        <Content>
          <Body>
            {introduction && <Introduction>{plainTextToHTML(introduction)}</Introduction>}
            <StructuredText
              data={content}
              renderBlock={({ record }) => {
                if(record.model){
                  switch(record.model.apiKey){
                    case 'inline_media_internal': return <InlineMediaInternal {...record} />;
                    case 'inline_media_external': return <InlineMediaExternal {...record} />;
                    case 'inline_gallery': return <InlineGallery {...record} />;
                    case 'inline_instagram': return <InlineInstagram {...record} />;
                    case 'inline_quote': return <InlineQuote {...record} />;
                    case 'inline_iframe': return <InlineIframe {...record} />;
                    case 'inline_soundcloud': return <InlineSoundcloud {...record} />;
                    case 'inline_tweet': return <InlineTweet {...record} />;
                  }
                }
                return null;
              }}
            />
          </Body>
          {((links && links.length > 0) || (tags && tags.length > 0)) &&
            <Footer>
              {links && links.length > 0 && 
                <FooterSection>
                  <FooterHeading>{labelBlogLinks}</FooterHeading>
                  <LinkList links={links} />
                </FooterSection>
              }
              {tags && tags.length > 0 &&
                <FooterSection>
                  <FooterHeading>{labelBlogTagged}</FooterHeading>
                  <ButtonLinks links={tags.map(tag => ({label:tag.name,page:tag}))} variant={ButtonVariant.SECONDARY} size={ButtonSize.SMALL} />
                </FooterSection>
              }
            </Footer>
          }
        </Content>
      </Container>
      {relatedArticles && relatedArticles.length > 0 &&
        <ThemeProvider theme={relatedTheme}>
          <BlockContainer layout={Layout.BLOCK} extraSpaceBefore={true} extraSpaceAfter={true}>
            <ArticleList items={relatedArticles} heading={labelBlogRelatedArticles} />
          </BlockContainer>
        </ThemeProvider>
      }
    </>
  )

};