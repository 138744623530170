import React, { ReactNode } from 'react';
import { Layout } from '../../../theme/layout';
import { Container, Content } from './container.styles';

export const CoverContainer = ({layout,children,background}:{layout:Layout,children:ReactNode,background?:ReactNode}) => {
  
  return (
    <Container>
      <Content layout={layout}>
        {children}
      </Content>
    </Container>
  )
};