import React from 'react';
import { Button } from '../button';
import { ButtonVariant } from '../button/button.types';
import { Container, Item } from './buttonLinks.styles';
import { ButtonLinksProps } from './buttonLinks.types';

export const ButtonLinks = ({links,variant,size}:ButtonLinksProps) => {
  return (
    <Container>
      {links?.map((link,index) => (
        <Item key={index}>
          <Button link={link} size={size} variant={variant ? variant : index === 0 ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY} />
        </Item>
      ))}
    </Container>
  )
}