import styled from 'styled-components';
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from '../../../theme/layout';

export const Container = styled.div`
  margin-bottom: ${getUnitAsPixels({multiplier:3})};
    
  ${getMediaQuery(Size.LARGE)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }

  &:last-child{
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  background-color: ${({theme}) => theme.primary.hex};
`