import styled from "styled-components";
import { getMediaQuery, getSpacerAsPixels, getUnitAsPixels, Size } from "../../../theme/layout";

export const Container = styled.div`
  position: relative;
  margin-bottom: ${getUnitAsPixels({multiplier:3})};
    
  ${getMediaQuery(Size.MEDIUM)}{
    margin-bottom: ${getSpacerAsPixels({multiplier:1})};
  }
`;